<template>
    <div>
        <strong>Order Item Upload</strong>
        <br>
        Order: {{ data.tracker_id }}
        <br>
        Company: {{ data.company_name }}
        <br>
        PO: {{ data.distributor_po }}
        <br>
        Job Tag: {{ data.job_tag }}
        <br>
        <br>

        <div class="form-group">
            <label>Drawing Type</label>
            <select class="form-control" v-model="state.reference2ID">
                <option value="6">Custom Engineering</option>
                <option value="1">Custom Existing</option>
                <option value="2">Custom New</option>
                <option value="3">Custom 3D</option>
                <option value="12">Custom E-Drawing</option>
                <option value="5">Customer Drawing</option>
                <option value="7">Spring Calculations</option>
                <option value="8">Shop Deviation</option>
                <option value="13">Batching Rework</option>
                <option value="9">Field Assembly Drawings</option>
                <option value="10">Assembly Part Labels</option>
                <option value="11">Conforming Calculation Drawing</option>
                <option value="14">3rd Party Submittal</option>
            </select>
        </div>
        <div class="form-group">
            <label>Item</label>
            <select class="form-control" v-model="state.referenceID">
                <option v-for="curb in appState.fileUploadContextData.metadata.curbs"
                        v-bind:key="curb.curb_data.tracker_invoice_id"
                        :value="curb.curb_data.tracker_invoice_id"
                >
                    {{ curb.curb_data.line }}{{ curb.curb_data.qty }} {{ curb.curb_data.nomenclature }} {{ curb.curb_data.tag }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import appStore from "@/store/App.store";

    export default {
        name: "TrackerCurb",
        data() {
            return {
                state: fileUploadStore.state,
                appState: appStore.state
            }
        },
        created() {
            /** @see curb_order_base.php */
            fileUploadStore.initialize(6);
            fileUploadStore.state.reference2ID = 6;
            fileUploadStore.state.allowAttach = true;
        },
        computed: {
            data: function () {
                if (appStore.state.fileUploadContextData.metadata) {
                    return appStore.state.fileUploadContextData.metadata;
                }

                return appStore.state.fileUploadContextData.data;
            }
        },
        mounted() {
            this.state.referenceID = this.data.curbs[0].curb_data.tracker_invoice_id;
            this.state.trackerID = this.data.tracker_id;
        },
        deactivated() {
            delete this.state.trackerID;
        }
    }
</script>

<style scoped>

</style>